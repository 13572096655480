import {
  serviceCard1,
  serviceCard2,
  serviceCard3,
  serviceCard4,

  //
  javascript,
  typescript,
  reactjs,
  redux,
  tailwind,
  nodejs,
  mongodb,
  git,
  threejs,
  d3,
  nextjs,
  express,
  mysql,
  java,
  springBoot,
  aws,

  //
  udemy,
  ltu,

  //
  foody,
  storeDashboard,
  budgetAnalysis,
  aiFitness,
  survey,
  netflix,
  chat,

  //
  linkedin,
  email,
  github,
} from "../assets";

export const navLinks = [
  {
    id: "about",
    title: "About",
  },
  {
    id: "work",
    title: "Work",
  },
  {
    id: "contact",
    title: "Contact",
  },
];

const services = [
  {
    title: "TypeScript/JavaScript Developer",
    icon: serviceCard1,
  },
  {
    title: "React/Next Developer",
    icon: serviceCard2,
  },
  {
    title: "Node Developer",
    icon: serviceCard3,
  },
  {
    title: "Java/Spring Boot Developer",
    icon: serviceCard4,
  },
];

const technologies = [
  {
    name: "JavaScript",
    icon: javascript,
  },
  {
    name: "TypeScript",
    icon: typescript,
  },
  {
    name: "React JS",
    icon: reactjs,
  },
  {
    name: "Redux Toolkit",
    icon: redux,
  },
  {
    name: "Next JS",
    icon: nextjs,
  },
  {
    name: "Tailwind CSS",
    icon: tailwind,
  },
  {
    name: "Three JS",
    icon: threejs,
  },
  {
    name: "D3 JS",
    icon: d3,
  },
  {
    name: "Node JS",
    icon: nodejs,
  },
  {
    name: "Express JS",
    icon: express,
  },
  {
    name: "Java",
    icon: java,
  },
  {
    name: "Spring Boot",
    icon: springBoot,
  },
  {
    name: "AWS",
    icon: aws,
  },
  {
    name: "MongoDB",
    icon: mongodb,
  },
  {
    name: "MySQL",
    icon: mysql,
  },

  {
    name: "git",
    icon: git,
  },
];

const courses = [
  {
    title: "JavaScript - The Complete Guide 2023",
    teacher: "Maximilian Schwarzmüller",
    icon: udemy,
    iconBg: "#E6DEDD",
    linkToProof:
      "https://www.linkedin.com/in/noah-ghiasvand-b5b8a61bb/details/certifications/1722336206947/single-media-viewer/?profileId=ACoAADM2KPcBEtEXIiept6bsYLD2UlPhRsDHLYg",
  },
  {
    title: "Understanding TypeScript",
    teacher: "Maximilian Schwarzmüller",
    icon: udemy,
    iconBg: "#E6DEDD",
    linkToProof:
      "https://www.linkedin.com/in/noah-ghiasvand-b5b8a61bb/details/certifications/1722357646537/single-media-viewer/?profileId=ACoAADM2KPcBEtEXIiept6bsYLD2UlPhRsDHLYg",
  },
  {
    title: "React - The Complete Guide (incl. Next.js, Redux)",
    teacher: "Maximilian Schwarzmüller",
    icon: udemy,
    iconBg: "#E6DEDD",
    linkToProof:
      "https://www.linkedin.com/in/noah-ghiasvand-b5b8a61bb/details/certifications/1722335797798/single-media-viewer/?profileId=ACoAADM2KPcBEtEXIiept6bsYLD2UlPhRsDHLYg",
  },
  {
    title: "React Testing Library and Jest: The Complete Guide",
    teacher: "Maximilian Schwarzmüller",
    icon: udemy,
    iconBg: "#E6DEDD",
    linkToProof:
      "https://www.linkedin.com/in/noah-ghiasvand-b5b8a61bb/details/certifications/1722248523823/single-media-viewer/?profileId=ACoAADM2KPcBEtEXIiept6bsYLD2UlPhRsDHLYg",
  },
  {
    title: "Next.js 14 & React - The Complete Guide",
    teacher: "Maximilian Schwarzmüller",
    icon: udemy,
    iconBg: "#E6DEDD",
    linkToProof:
      "https://www.linkedin.com/in/noah-ghiasvand-b5b8a61bb/details/certifications/1722343612529/single-media-viewer/?profileId=ACoAADM2KPcBEtEXIiept6bsYLD2UlPhRsDHLYg",
  },
  {
    title: "NodeJS - The Complete Guide (MVC, REST APIs, GraphQL, Deno)",
    teacher: "Maximilian Schwarzmüller",
    icon: udemy,
    iconBg: "#E6DEDD",
    linkToProof:
      "https://www.linkedin.com/in/noah-ghiasvand-b5b8a61bb/details/certifications/1722358949626/single-media-viewer/?profileId=ACoAADM2KPcBEtEXIiept6bsYLD2UlPhRsDHLYg",
  },
  {
    title: "Java 17 Masterclass",
    teacher: "Tim Buchalka",
    icon: udemy,
    iconBg: "#E6DEDD",
    linkToProof:
      "https://www.linkedin.com/in/noah-ghiasvand-b5b8a61bb/details/certifications/1722349766396/single-media-viewer/?profileId=ACoAADM2KPcBEtEXIiept6bsYLD2UlPhRsDHLYg",
  },
  {
    title: "Master Spring 6, Spring Boot 3, REST, JPA, Hibernate",
    teacher: "Madan Reddy",
    icon: udemy,
    iconBg: "#E6DEDD",
    linkToProof:
      "https://www.linkedin.com/in/noah-ghiasvand-b5b8a61bb/details/certifications/1722343556130/single-media-viewer/?profileId=ACoAADM2KPcBEtEXIiept6bsYLD2UlPhRsDHLYg",
  },
  {
    title: "Testing Spring Boot",
    teacher: "John Thompson",
    icon: udemy,
    iconBg: "#E6DEDD",
    linkToProof:
      "https://www.linkedin.com/in/noah-ghiasvand-b5b8a61bb/details/certifications/1722344874074/single-media-viewer/?profileId=ACoAADM2KPcBEtEXIiept6bsYLD2UlPhRsDHLYg",
  },
  {
    title: "Initial programming in Java",
    teacher: "Sandeep Patil",
    icon: ltu,
    iconBg: "#E6DEDD",
  },
  {
    title: "Objektorienterad programmering i Java",
    teacher: "Susanne Fahlman",
    icon: ltu,
    iconBg: "#E6DEDD",
  },
];

const projects = [
  {
    name: "Foody",
    description:
      "A food delivery application similar to Foodora, allowing users to authenticate their accounts, select items, place orders, and complete payments using the Stripe API. The items featured in the app are sourced from the Admin Dashboard, which will be presented as the next project.",
    tags: [
      {
        name: "Next.js",
        color: "blue-text-gradient",
      },
      {
        name: "Shadcn",
        color: "green-text-gradient",
      },
      {
        name: "TailwindCSS",
        color: "red-text-gradient",
      },
      {
        name: "Zustand",
        color: "pink-text-gradient",
      },
    ],
    image: foody,
    source_code_link:
      "https://github.com/mohammadGhiasvand/food-ordering-nextjs",
    live_link: "https://food-ordering-nextjs-phi.vercel.app/",
    linkedin_link: "",
  },
  {
    name: "Admin Dashboard",
    description:
      "An admin dashboard application that serves as the API foundation for the Foody app. This application provides insightful statistics on purchases across various categories, utilizing the Recharts library for data visualization. Additionally, it enables users to create multiple stores and automatically generate the necessary APIs for each one.",
    tags: [
      {
        name: "Next.js",
        color: "blue-text-gradient",
      },
      {
        name: "Firebase",
        color: "green-text-gradient",
      },
      {
        name: "Shadcn",
        color: "red-text-gradient",
      },
      {
        name: "Zustand",
        color: "pink-text-gradient",
      },
    ],
    image: storeDashboard,
    source_code_link:
      "https://github.com/mohammadGhiasvand/multistore-dashboard-nextjs",
    live_link: "https://multistore-dashboard-nextjs.vercel.app/",
    linkedin_link: "",
  },
  {
    name: "budgetAnalysis",
    description:
      "A budget tracker application that allows users to create transactions and categorize them into two groups: income and expenses. The app stands out for its impressive data visualization and modern styling, which enhance the user experience and make financial management more intuitive.",
    tags: [
      {
        name: "Next.js",
        color: "blue-text-gradient",
      },
      {
        name: "Shadcn",
        color: "red-text-gradient",
      },
      {
        name: "TanStack Query",
        color: "green-text-gradient",
      },
      {
        name: "Zustand",
        color: "pink-text-gradient",
      },
      {
        name: "Postgres",
        color: "green-text-gradient",
      },
    ],
    image: budgetAnalysis,
    source_code_link:
      "https://github.com/mohammadGhiasvand/budget-tracker-nextjs",
    live_link: "https://budget-tracker-nextjs-eta.vercel.app",
    linkedin_link: "",
  },
  {
    name: "Fast & Fit",
    description:
      "Fast and Fit is a PWA built with Next.js that utilizes the OpenAI API to deliver daily motivational AI-generated responses. Offering a subscription model with three challenge difficulty levels, the app helps users stay focused on their fitness goals by providing tailored support and incentives throughout the day. It's the perfect tool for enhancing motivation and accountability in fitness journeys.",
    tags: [
      {
        name: "Next.js",
        color: "blue-text-gradient",
      },
      {
        name: "AWS RDS (MySQL)",
        color: "green-text-gradient",
      },
      {
        name: "Shadcn",
        color: "red-text-gradient",
      },
      {
        name: "PWA",
        color: "pink-text-gradient",
      },
      {
        name: "OpenAI API",
        color: "blue-text-gradient",
      },
    ],
    image: aiFitness,
    source_code_link: "https://github.com/mohammadGhiasvand/ai-fitness-nextjs",
    live_link: "https://ai-fitness-nextjs.vercel.app",
    linkedin_link: "",
  },
  {
    name: "Survey",
    description:
      "A fully functional Instagram-like application that allows users to create, edit, and delete posts, as well as like and comment on each entry. The application features real-time updates in the comments section, facilitated by the WebSocket API.",
    tags: [
      {
        name: "react.js",
        color: "blue-text-gradient",
      },
      {
        name: "node.js",
        color: "green-text-gradient",
      },
      {
        name: "mongodb",
        color: "red-text-gradient",
      },
      {
        name: "socket.io",
        color: "pink-text-gradient",
      },
    ],
    image: survey,
    source_code_link: "https://github.com/mohammadGhiasvand/Survey.ts",
    live_link: "https://mohammadghiasvand.github.io/survey.github.io/",
    linkedin_link: "",
  },
  {
    name: "Netflix",
    description:
      "Netflix Web application clone that provides movies based on their categories from the TMDB facility.",
    tags: [
      {
        name: "react.js",
        color: "blue-text-gradient",
      },
      {
        name: "node.js",
        color: "green-text-gradient",
      },
      {
        name: "mongodb",
        color: "red-text-gradient",
      },
    ],
    image: netflix,
    source_code_link: "https://github.com/mohammadGhiasvand/Netflix-clone",
    live_link:
      "https://mohammadghiasvand.github.io/movieApp-react-node-deploy/",
    linkedin_link: "",
  },
  {
    name: "Talkative",
    description:
      "Web-based chat platform that allows users to chat real-time with each other.",
    tags: [
      {
        name: "react.js",
        color: "blue-text-gradient",
      },
      {
        name: "node.js",
        color: "green-text-gradient",
      },
      {
        name: "mongodb",
        color: "red-text-gradient",
      },
      {
        name: "socket.io",
        color: "pink-text-gradient",
      },
    ],
    image: chat,
    source_code_link: "https://github.com/mohammadGhiasvand/chat-clone",
    live_link: "https://mohammadghiasvand.github.io/talkative.github.io/",
    linkedin_link: "",
  },
];

const socials = [
  {
    name: "GitHub",
    logo: github,
    address: "https://github.com/mohammadGhiasvand/",
  },
  {
    name: "LinkedIn",
    logo: linkedin,
    address: "https://www.linkedin.com/in/noah-ghiasvand-b5b8a61bb/",
  },
  {
    isEmail: true,
    name: "Email",
    logo: email,
    address: "mailto:m.ghiasvand.work@gmail.com",
  },
];

export { services, technologies, courses, projects, socials };
